import { ActionTree } from 'vuex'

import {
  HttpRespBase,
  IPSpeakerStatusResp,
  IPSpeakerStatusStore,
  MusicDecrementResp,
  MusicIncrementResp,
  MusicInfoResp,
  MusicList,
  MusicListResp,
  MusicPlayResp,
} from './models/music'

interface State {
  selectedMusicId: Number
  musicList: MusicList[]
  volume: Number
  selectedMusic: MusicInfoResp
  ipSpeakerStatus: IPSpeakerStatusStore
}

export const state = () => ({
  selectedMusicId: 0,
  musicList: [],
  volume: 0,
  selectedMusic: {},
  ipSpeakerStatus: {
    status: '',
    pattern_number: '0',
  },
})

export const getters = {
  volume: (state: State) => state.volume,
  musicList: (state: State) => state.musicList,
  selectedMusic: (state: State) => state.selectedMusic,
  selectedMusicId: (state: State) => state.selectedMusicId,
  ipSpeakerStatus: (state: State) => state.ipSpeakerStatus,
}

export const mutations = {
  plusVolume(state: State, { volume }: State) {
    state.volume = volume
  },
  minusVolume(state: State, { volume }: State) {
    state.volume = volume
  },
  setIPSpeakerStatus(
    state: State,
    { status, pattern_number }: IPSpeakerStatusResp
  ) {
    state.ipSpeakerStatus.status = status
    state.ipSpeakerStatus.pattern_number = pattern_number + ''
  },
  setSelectedMusic(state: State, musicInfo: MusicInfoResp) {
    state.selectedMusic = musicInfo
  },
  setMusicList(state: State, musicList: MusicList[]) {
    state.musicList = musicList
  },
}

export const actions: ActionTree<State, State> = {
  async playSelectedMusic({}, { endpoint, location, pattern_no, port }) {
    const resp: HttpRespBase<MusicPlayResp> = await this.$axios.$post(
      'api/v1/pattern/play',
      {
        endpoint,
        location,
        pattern_no,
        port,
      }
    )

    if (!resp.result) {
      throw resp
    }

    console.log('playSelectedMusic', { ...resp.response })
  },
  async plusVolume({ commit }, { endpoint, location, port }) {
    const resp: HttpRespBase<MusicIncrementResp> = await this.$axios.$post(
      'api/v1/volume/inc-master',
      {
        endpoint,
        location,
        port,
      }
    )

    if (!resp.result) {
      throw resp
    }
    commit('plusVolume', { volume: resp.response.volume })
  },
  async minusVolume({ commit }, { endpoint, location, port }) {
    const resp: HttpRespBase<MusicDecrementResp> = await this.$axios.$post(
      'api/v1/volume/dec-master',
      {
        endpoint,
        location,
        port,
      }
    )

    if (!resp.result) {
      throw resp
    }

    commit('minusVolume', { volume: resp.response.volume })
  },
  async fetchIPSpeakerStatus({ commit }, { endpoint, location, port }) {
    console.log('fetchIPSpeakerStatus', { endpoint, location, port })

    const resp: HttpRespBase<IPSpeakerStatusResp> = await this.$axios.$post(
      'api/v1/info/status',
      {
        endpoint,
        location,
        port,
      }
    )

    if (!resp.result) {
      throw resp
    }

    commit('setIPSpeakerStatus', { ...resp.response })
  },
  async fetchSelectedMusic(
    { commit },
    { endpoint, location, pattern_no, port }
  ) {
    console.log('fetchSelectedMusic req', {
      endpoint,
      location,
      pattern_no,
      port,
    })

    const resp: HttpRespBase<MusicInfoResp> = await this.$axios.$post(
      'api/v1/pattern/info',
      {
        endpoint,
        location,
        pattern_no,
        port,
      }
    )

    if (!resp.result) {
      throw resp
    }

    console.log('fetchSelectedMusic', { ...resp.response })
    commit('setSelectedMusic', resp.response)
  },

  async fetchMusicList({ commit }, { endpoint, location, port }) {
    console.log('fetchMusicList req', { endpoint, location, port })

    const resp: HttpRespBase<MusicListResp> = await this.$axios.$post(
      'api/v1/pattern/list',
      {
        endpoint,
        location,
        port,
      }
    )

    const patterns = Object.entries(resp.response)

    const musicList: MusicList[] = patterns
      .map(([key, value]) => {
        const number = key.replace('pattern', '')

        return {
          ...value,
          pattern_no: number,
          key,
        }
      })
      .filter((list) => list.target !== '')

    if (!resp.result) {
      throw resp
    }

    console.log('fetchMusicList', { musicList })
    commit('setMusicList', musicList)
  },
}
