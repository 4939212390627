//
//
//
//
//
//
//
//
//
//
//
//
//

import ListIcon from './icons/ListIcon.vue'
import MusicIcon from './icons/MusicIcon.vue'

export default {
  props: {
    fills: {
      type: Object,
      default: () => ({ musicFill: '#000', listFill: '#000' }),
    },
  },
  components: { ListIcon, MusicIcon },
}
