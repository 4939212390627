export const Button = () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Jacket = () => import('../../components/Jacket.vue' /* webpackChunkName: "components/jacket" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const VolumeButton = () => import('../../components/VolumeButton.vue' /* webpackChunkName: "components/volume-button" */).then(c => wrapFunctional(c.default || c))
export const IconsBackArrow = () => import('../../components/icons/BackArrow.vue' /* webpackChunkName: "components/icons-back-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckIcon = () => import('../../components/icons/CheckIcon.vue' /* webpackChunkName: "components/icons-check-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsListIcon = () => import('../../components/icons/ListIcon.vue' /* webpackChunkName: "components/icons-list-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsMinusIcon = () => import('../../components/icons/MinusIcon.vue' /* webpackChunkName: "components/icons-minus-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsMusicIcon = () => import('../../components/icons/MusicIcon.vue' /* webpackChunkName: "components/icons-music-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPlusIcon = () => import('../../components/icons/PlusIcon.vue' /* webpackChunkName: "components/icons-plus-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsRoomIcon = () => import('../../components/icons/RoomIcon.vue' /* webpackChunkName: "components/icons-room-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsVolumeDownIcon = () => import('../../components/icons/VolumeDownIcon.vue' /* webpackChunkName: "components/icons-volume-down-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsVolumeIcon = () => import('../../components/icons/VolumeIcon.vue' /* webpackChunkName: "components/icons-volume-icon" */).then(c => wrapFunctional(c.default || c))
export const MusicListBackButton = () => import('../../components/musicList/BackButton.vue' /* webpackChunkName: "components/music-list-back-button" */).then(c => wrapFunctional(c.default || c))
export const MusicListItem = () => import('../../components/musicList/Item.vue' /* webpackChunkName: "components/music-list-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
