import { ActionTree } from 'vuex'
import { SoracomNapterResp } from './models/soracom-napter'

interface State {
  endpoint: String
}

export const state = () => ({
  endpoint: '',
})

export const getters = {
  endpoint: (state: State) => state.endpoint,
}

export const mutations = {
  setEndpoint(state: State, { endpoint }: SoracomNapterResp) {
    state.endpoint = endpoint
  },
}

export const actions: ActionTree<State, State> = {
  async fetchEndpoint({ commit }, { port, location }) {
    const resp = await this.$axios.$post<SoracomNapterResp>(
      'api/v1/napter/connect',
      {
        port,
        location,
      }
    )

    console.log('fetchEndpoint', { endpoint: resp.endpoint })
    commit('setEndpoint', { endpoint: resp.endpoint })
  },
}
