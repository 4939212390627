//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    fill: {
      type: String,
      default: '#000',
    },
  },
}
