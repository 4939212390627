import { ActionTree } from 'vuex'

interface State {
  roomInfo: RoomInfo
}

interface RoomInfo {
  port: String
  location: String
  link: String
}

export const state = () => ({ roomInfo: {} })

export const getters = {
  roomInfo: (state: State) => state.roomInfo,
}

export const mutations = {
  setRoomInfo(state: State, { port, location, link }: RoomInfo) {
    state.roomInfo.port = port
    state.roomInfo.location = location
    state.roomInfo.link = link
  },
}

export const actions: ActionTree<RoomInfo, State> = {
  async setRoomInfo({ commit }, { port, location, link }: RoomInfo) {
    console.log('setRoomInfo', { port, location, link })
    commit('setRoomInfo', { port, location, link })
  },
}
