//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      fills: { musicFill: 'orange', listFill: '#000' },
    }
  },
  created() {
    this.setListener()
  },
  methods: {
    setListener() {
      this.$nuxt.$on('updateFooter', this.setFooter)
    },
    setFooter(path) {
      if (path === '/music') {
        this.fills.musicFill = 'orange'
        this.fills.listFill = '#000'
      } else {
        this.fills.musicFill = '#000'
        this.fills.listFill = 'orange'
      }
    },
  },
}
