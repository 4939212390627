import { Context } from '@nuxt/types'
import https from 'https'

export default function ({ $axios }: Context) {
  $axios.onRequest((config) => {
    config.httpsAgent = new https.Agent({
      rejectUnauthorized: process.env.NODE_ENV === 'prod',
    })
  })
}
